body {
  overflow-x: hidden;
}

html,
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F9F5E8;
}

html,
body,
#root {
  min-height: 100vh;
  .ReactModal__Body--open {
    overflow: hidden;
  }
}

@font-face {
  font-family: "Unify Sans";
  src: url("./assets/fonts/UnifySans_Regular.woff2") format("woff2");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Unify Sans";
  src: url("./assets/fonts/UnifySans_Bold.woff2") format("woff2");
  font-style: bold;
  font-weight: 700;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/roboto-regular.woff2") format("woff2");
  font-style: normal;
  font-weight: 400;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
